import React, { Component } from 'react';
import { Modal, ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Map from './Map';
import { LocalParking, FreeBreakfastOutlined, LocalHospitalOutlined, StarTwoTone, LocalCarWashOutlined } from '@material-ui/icons';

class MapPdf extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map: {},
            geocoder: {},
            locations: {
                lat: 34.120860,
                lng: -118.249230
            },
            zoom: 15,
            modal: false,
            icon: '',
            iconType: '',
            place: {},
            latLng: {},
            markers: [],
            locationTo: false,
            origin: {},
            destination: {}
        }

    }

    createInfoWindow(e, results, status) {

        var add = results[0].formatted_address;
        if ((this.state.iconType !== 'location-from') && (this.state.iconType !== 'trucking')) {
            document.getElementById(this.state.iconType).innerHTML = add
        }
        const infoWindow = new window.google.maps.InfoWindow({
            content: `<div id="infowindow">${add}</div>`,
            position: { lat: e.lat(), lng: e.lng() }
        })
        setTimeout(() => {
            infoWindow.open(this.state.map);
        }, 800)

        setTimeout(() => {
            infoWindow.setMap(null);
        }, 3000)
    }
    placeMarker(e) {
        const map = this.state.map
        if (this.state.markers.length !== 0) {
            this.state.markers.forEach(element => {
                var marker = (element.iconType === this.state.iconType) && (element.iconType !== 'location-from') ? element : null
                if (marker) {
                    marker.setMap(null);
                }
            });
        }
        var marker = new window.google.maps.Marker({
            title: 'Right Click for delete icon',
            position: e,
            map: map,
            icon: `http://maps.google.com/mapfiles/ms/icons/${this.state.icon}`,
            iconType: this.state.iconType,
            draggable: (this.state.iconType === 'location') || (this.state.iconType === 'location-from') ? false : true,
        });
        this.setState(prevState => ({
            markers: [...prevState.markers, marker]
        }))
        marker.addListener('rightclick', e => {
            if ((marker.iconType !== 'location-from') && (marker.iconType !== 'trucking')) {
                document.getElementById(marker.iconType).innerHTML = ''
            }
            marker.setMap(null);

        })
        marker.addListener('click', e => {
            this.geoCoder(e.latLng)
        })
        marker.addListener('dragend', e => {
            this.setState({
                iconType: marker.iconType
            })
            if (marker.iconType === 'location') {
                this.props.parentCallback(e.latLng);
                this.setState({
                    destination: e.latLng
                })
            }
            if (marker.iconType === 'location-from') {
                this.setState({
                    origin: e.latLng
                })
            }
            if ((marker.iconType === 'location-from' || marker.iconType === 'location') && this.state.locationTo && Object.keys(this.state.origin).length !== 0 && Object.keys(this.state.destination).length !== 0) {
                this.directionService(map)
            }
            this.geoCoder(e.latLng)
        })
        if ((marker.iconType === 'location-from' || marker.iconType === 'location') && this.state.locationTo && Object.keys(this.state.origin).length !== 0 && Object.keys(this.state.destination).length !== 0) {
            this.directionService(map)
        }
        this.geoCoder(e)

    }
    directionService(map) {
        const directionsService = new window.google.maps.DirectionsService();

        var start = `${this.state.origin.lat()}, ${this.state.origin.lng()}`;
        var end = `${this.state.destination.lat()}, ${this.state.destination.lng()}`;
        var request = {
            origin: start,
            destination: end,
            travelMode: window.google.maps.DirectionsTravelMode.DRIVING
        };
        directionsService.route(request, function (response, status) {

            if (status === window.google.maps.DirectionsStatus.OK) {

                new window.google.maps.DirectionsRenderer({
                    zoom: 1,
                    suppressMarkers: true,
                    draggable: true,
                    map: map,
                    directions: response,
                    panel: document.getElementById('panel'),
                    polylineOptions: {
                        strokeColor: '#bd2130',
                        strokeOpacity: 1.0,
                        strokeWeight: 5
                    }
                });
            }
        });
    }
    updateDestinationOnDrag(e) {
        console.log(e)
    }
    geoCoder(e) {
        this.state.geocoder.geocode({ 'latLng': e }, (results, status) => {
            this.createInfoWindow(e, results, status)
        });
    }
    getIconType(e) {
        if (e.target.id === '1') {
            this.props.parentCallback(this.state.latLng);
            this.setState({
                locationTo: true,
                destination: this.state.latLng,
                iconType: 'location',
                icon: 'green-dot.png'
            }, () => this.placeMarker(this.state.latLng))
        } else if (e.target.id === '2') {
            this.setState({
                iconType: 'parking',
                icon: 'parkinglot.png'
            }, () => this.placeMarker(this.state.latLng))
        } else if (e.target.id === '3') {
            this.setState({
                iconType: 'hospital',
                icon: 'hospitals.png'
            }, () => this.placeMarker(this.state.latLng))
        } else if (e.target.id === '4') {
            this.setState({
                iconType: 'trucking',
                icon: 'truck.png'
            }, () => this.placeMarker(this.state.latLng))
        } else if (e.target.id === '5') {
            this.setState({
                iconType: 'restaurant',
                icon: 'restaurant.png'
            }, () => this.placeMarker(this.state.latLng))
        } else if (e.target.id === '6') {
            this.setState({
                iconType: 'location-from',
                icon: 'blue-dot.png',
                origin: this.state.latLng
            }, () => this.placeMarker(this.state.latLng))
        }
        this.setState({
            modal: false
        })
    }
    autoComplete(e) {
        this.setState({
            latLng: e,
            modal: true
        });
    }

    render() {
        return (
            <>
                <Modal size="sm" show={this.state.modal} onHide={e => this.setState({ modal: false })} aria-labelledby="example-modal-sizes-title-sm">
                    <Modal.Header closeButton>
                        <Modal.Title>Choose icon for this location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup>
                            {this.state.locationTo
                                ? <ListGroup.Item action variant="secondary" id="6" onClick={(e) => this.getIconType(e)}> Location From<i className='location-icon'><StarTwoTone /></i></ListGroup.Item>
                                : <ListGroup.Item action variant="secondary" id="1" onClick={(e) => this.getIconType(e)}> Location To<i className='location-icon'><StarTwoTone /></i></ListGroup.Item>
                            }
                            <ListGroup.Item action variant="dark" id="2" onClick={(e) => this.getIconType(e)}> Parking <i className='location-icon'><LocalParking /></i></ListGroup.Item>
                            <ListGroup.Item action variant="secondary" id="3" onClick={(e) => this.getIconType(e)}> Hospital <i className='location-icon'><LocalHospitalOutlined /></i></ListGroup.Item>
                            <ListGroup.Item action variant="dark" id="4" onClick={(e) => this.getIconType(e)}> Trucks <i className='location-icon'><LocalCarWashOutlined /></i></ListGroup.Item>
                            <ListGroup.Item action variant="secondary" id="5" onClick={(e) => this.getIconType(e)}> Catering <i className='location-icon'><FreeBreakfastOutlined /></i></ListGroup.Item>



                        </ListGroup>
                    </Modal.Body>
                </Modal>
                <Map
                    height='49vh'
                    id="mainMap"
                    options={{
                        center: this.state.locations,
                        zoom: this.state.zoom,
                        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                        disableDefaultUI: true,
                    }}
                    onMapLoad={map => {
                        const geocoder = new window.google.maps.Geocoder();
                        this.setState({
                            map: map,
                            geocoder: geocoder
                        })
                        var input = document.getElementById('pac-input');
                        var autocomplete = new window.google.maps.places.Autocomplete(input,
                            { "types": ["geocode"] });
                        autocomplete.addListener('place_changed', () => {
                            const place = autocomplete.getPlace();
                            if (!place.geometry) {
                                // User entered the name of a Place that was not suggested and
                                // pressed the Enter key, or the Place Details request failed.
                                toast.error('No details available for input: ' + place.name)

                                return;
                            }
                            input.value = '';
                            this.autoComplete(place.geometry.location)
                        })

                        map.addListener('click', e => {
                            this.setState({
                                latLng: e.latLng,
                                modal: true
                            });
                        });
                    }}
                />
            </>
        );
    }
}

export default MapPdf;