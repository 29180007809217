import React, { Component } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import SiteHeader from './SiteHeader';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Input, Label, FormGroup, Form } from 'reactstrap';
import StorageService from './../storage/StorageService';
import { userService } from './../services';
import apiUrl from './../constants';
import { toast } from 'react-toastify';
import { MDBInput } from 'mdbreact';
import Swal from 'sweetalert2';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            rows: [],
            list: [],
            deleteArr: [],
            errors: {},
            toggleStatus: true,
            modal: false,
            data: {},
            toggleStatusPopup: true,
            description: '',
            checked: false,
            width: window.innerWidth,
            height: window.innerHeight,
            modal1: false,
            fields1: {
                to: '',
                cc: '',
                bcc: '',
                subject: '',
                message: '',
            },
            errors1: {},
            options: {},
            userData: [],
            loading: true
        }
        this.handleCheckTerms = this.handleCheckTerms.bind(this);
        this.handleChangeModal = this.handleChangeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // This method is the least used lifecycle method 
    // and called before any HTML element is rendered
    componentDidMount() {
        document.title = "Projects | SetlinkLive";
        const { currentUser } = this.props;
        if (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin'   ) {
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "status",
                        label: "Status",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "projectName",
                        label: "Project Name",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "tokens",
                        label: "Publish Token",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "link",
                        label: "Signup Link",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "action",
                        label: "Actions",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                ],
            });
        } else {
            if (StorageService.getLogin().profile.termsPopup === true && StorageService.getLogin().profile.agreeTermsPopup === false) {
                this.getPopupData();
                this.setState({ modal: true });
            }
            this.setState({
                columns: [
                    {
                        name: "sno",
                        label: "S No.",
                        options: {
                            filter: false,
                            sort: false,
                        }
                    },
                    {
                        name: "projectName",
                        label: "Project Name",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: "tokens",
                        label: "Publish Token",
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                ]
            });
        }
        this.loader()
        this.getUser();
        this.getProjects();
    }
    /**
    * @loader - data loading process
    */
    loader = () => {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 4000);
    }

    // Get User's details
    getUser = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StorageService.getLogin().token}`
            },
        };
        return fetch(`${apiUrl}admin/users/${StorageService.getLogin().profile.userId}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    StorageService.clearLogin();
                    this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                    return;
                }
            })
            .then(data => {
                this.setState({ fields: data });
                const storageData = StorageService.getLogin();
                if (storageData !== null) {
                    if (storageData.profile.userType !== data.userType) {
                        storageData.profile.userType = data.userType;
                        StorageService.setLogin(storageData);
                    }
                    if (storageData.profile.status !== data.status) {
                        storageData.profile.status = data.status;
                        if (data.status === "inactive") {
                            StorageService.clearLogin();
                        } else {
                            StorageService.setLogin(storageData);
                        }
                    } else if (storageData.profile.status === data.status) {
                        if (storageData.profile.status === "inactive") {
                            StorageService.clearLogin();
                        }
                    }
                }
            });
    }

    // Get Data on popup window
    getPopupData = async () => {
        try {
            const data = await userService.httpApi('GET', 'admin/terms', null);
            this.setState({ description: data[0].description });
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Data Toggle handler
    toggleHandler = () => {
        this.setState({ toggleStatus: (this.state.toggleStatus) ? false : true })
    }

    // Slide Button's 
    handleChangeBtnSwitch = async (value, data) => {
        try {
            let projectData = this.state.rows;
            const index = projectData.findIndex(x => x.id === data._id);
            if (index > -1) {
                projectData[index].status = value;
                const id = projectData[index].id;
                await userService.httpApi('PATCH', `admin/projects/${id}`, JSON.stringify({
                    projectName: projectData[index].projectName,
                    status: value,
                    tokenId: projectData[index].tokenId,
                }));
                this.setState({ rows: projectData });
                toast.success('Project status updated successfully');
                this.getProjects();
            }
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Remove selected Project
    deleteProject(id) {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You will not be able to recover this project!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then(async (result) => {
                if (result.value) {
                    const projectData = this.state.rows;
                    const index = projectData.findIndex(x => x.id === id);
                    if (index > -1) {
                        await userService.httpApi('DELETE', `admin/projects/${id}`, null);
                        projectData.splice(index, 1);
                        this.setState({ rows: projectData });
                        this.getProjects();
                        Swal.fire(
                            'Deleted!',
                            'Project has been deleted.',
                            'success'
                        )
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'Cancelled',
                        'project is safe :)',
                        'error'
                    )
                }
            })
        } catch (error) {
            toast.warn(error.message);
        }
    }

    // Copy Url on clipboard
    copyToClipboard(e, txt) {
        e.preventDefault();
        const textField = document.createElement('textarea');
        textField.innerText = txt;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toast.success('Link Copied');
    }

    // Send email
    sendEmail() {
        this.setState({ modal1: true });
    }

    // Close modal
    modelClose = () => {
        this.setState({ fields1: {}, errors1: {} });
        this.setState({ modal1: false });
    }

    // Bind input data on event onChange
    handleChangeModal = (field, e) => {
        let fields1 = this.state.fields1;
        fields1[field] = e.target.value;
        this.setState({ fields1 });
    }

    // Check input Validations
    handleValidation = () => {
        let errors1 = {};
        let formIsValid = true;
        if (this.state.fields1.to.trim().length === 0) {
            formIsValid = false;
            errors1["to"] = 'To Email is required.';
        }
        if (this.state.fields1.subject.trim().length === 0) {
            formIsValid = false;
            errors1["subject"] = 'Subject is required.';
        }
        this.setState({ errors1: errors1 });
        return formIsValid;
    }

    // Submit form data
    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                const data = {
                    to: this.state.fields1.to,
                    cc: this.state.fields1.cc,
                    bcc: this.state.fields1.bcc,
                    subject: this.state.fields1.subject,
                    message: this.state.fields1.message,
                }
                await userService.httpApi('POST', `send/email`, JSON.stringify(data));
                toast.success('Email sent successfully');
                this.setState({ modal1: false });
                this.props.history.push(`${process.env.PUBLIC_URL}/projects`);
            }
            
        } catch (error) {
            toast.warn(error);
        }
    }

    // Find data indexes for get ids for multi delete
    handleGetIndex = (id) => {
        const data = this.state.deleteArr;
        const arr = this.state.userData;
            if(arr[id]) {
                data.push(arr[id]._id);
            }
            this.setState({ deleteArr: data });
            
    };

    // Get all Projects
    getProjects = async () => {
        const { currentUser } = this.props;
        try {
            if (currentUser.currentUser === 'superAdmin') {
                const data = await userService.httpApi('GET', 'admin/projects', null);
                this.drawRows(data);
                this.setState({ userData: data });
            } else if (currentUser.currentUser === 'admin') {
                const data = await userService.httpApi('GET', `tadmin/projects/${currentUser.id}`, null);
                this.drawRows(data);
                this.setState({ userData: data }); 
            } else {
                const data = await userService.httpApi('GET', `user/projects/${currentUser.id}`, null);
                this.drawRows(data);   
                this.setState({ userData: data });         
            }
        } catch (error) {
            if (error === 'Token Expired') {
                toast.warn(error);
                StorageService.clearLogin();
            } else if (error === 'Login session expired') {
                toast.warn(error);
                this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                StorageService.clearLogin();
            } else {
                toast.warn(error);
            }
        }
    }

    // Create table rows
    drawRows(data) {
        let rows = [];
        const { currentUser } = this.props;
        if (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin' ) {
            data.map((x, index) =>
                rows.push({
                    sno: index + 1,
                    id: x._id,
                    tokenId: x.tokenId,
                    projectName: x.projectName,
                    tokens: (x.tokens.length === 0) ? '-' : x.tokens.map((y) => y.label).join(','),
                    status: (x.status === true) ? <MDBInput label=" " onChange={() => this.handleChangeBtnSwitch(false, x)} type="checkbox" className="switch-checkbox" checked={true} id={`switch${x._id}`} /> : <MDBInput label=" " type="checkbox" className="switch-checkbox" onChange={() => this.handleChangeBtnSwitch(true, x)} checked={false} id={`switch${x._id}`} />,
                    link: <><Button onClick={(e) => this.copyToClipboard(e, `http://${window.location.host}/signup/${x._id}`)} className='btn-sm'>Copy</Button><Button onClick={() => this.sendEmail()} className='btn-sm send-email'>Send Email</Button></>,
                    action: <><Link to={`${process.env.PUBLIC_URL}/edit-project/${x._id}`} className="btn-sm btn btn-success m-1"><i className="fa fa-edit"></i></Link><Button className="btn-sm btn-danger m-1" id={x._id} onClick={() => this.deleteProject(x._id)}><i className="fa fa-trash"></i></Button></>
                })
            )
            this.setState({ rows: rows });
        } else {
            
            data.map((y, index) => 
                y.projects.map((x) => rows.push({
                    sno: index + 1,
                    id: x._id,
                    projectName: x.projectName,
                    tokens: <Link to={`${process.env.PUBLIC_URL}/view-tokens/${y.teamprojects[0].teamId}/${x._id}`} className="btn-sm btn btn-twitter m-1">Tokens</Link>,
                })
                )
            );
            this.setState({ rows: rows });
        }
    }

    // Modal Close
    modelClose() {
        this.setState({ modal: false });
    }

    // Using for Checkboxes
    handleCheckTerms = async (e) => {
        this.setState({ checked: e.target.checked });
    }

    // Accept Terms with checkbox
    acceptTerms = async () => {
        await userService.httpApi('PATCH', `admin/users/${StorageService.getLogin().profile.userId}`, JSON.stringify({
            agreeTermsPopup: this.state.checked,
        }));
        const storageData = StorageService.getLogin();
        if (storageData !== null) {
            storageData.profile.termsPopup = false;
            StorageService.setLogin(storageData);
        }
        this.setState({ modal: false });
        toast.success('Terms accepted successfully.');
    }

    // Delete multiple data on single click
    handleClick = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover the checked projects!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.value) {
                const data = { id: this.state.deleteArr };
                await userService.httpApi('DELETE', 'multiple/projects/delete', JSON.stringify(data));
                this.getProjects();
                Swal.fire(
                    'Deleted!',
                    'Checked projects deleted successfully!',
                    'success'
                )
                toast.success('Checked projects deleted successfully!');
                this.getProjects();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Checked projects are safe :)',
                    'error'
                )
            }
        })
    };

    // Returns a JSX
    // Because that component needs to display the HTML markup or we can say JSX syntax
    render() {
        const { currentUser } = this.props;
        const options = {
            rowsPerPage: 10,
            rowsPerPageOptions: [10,15,20],
            filter: true,
            filterType: 'dropdown',
            serverSide: false,
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: (currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin' || currentUser.currentUser === 'broadcaster') ? true : false,
            selectableRowsOnClick: false,
            textLabels: {
                body: {
                    noMatch: this.state.loading ?
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                    :
                        'Sorry, there is no matching data to display',
                },
            },
            onRowsDelete: (rowsDeleted, newData) => {
                
                for(var i = 0; i < rowsDeleted.data.length; i++) {
                   
                    if (rowsDeleted.data[i].index > -1) {
                        this.handleGetIndex(rowsDeleted.data[i].index);
                    } 
                    
                }
                this.handleClick();
                return false;
            }
        }
        return (
            <>
                <SiteHeader />
                <div className="my-4 my-md-5 common-table custom_check container">
                    <div className="page_wrapper">
                        <Row className="table_btn mb-4">
                            
                            {(currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin' || currentUser.currentUser === 'broadcaster') ?
                                <div>
                                   
                                </div> : ''}
                            {(currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin' || currentUser.currentUser === 'broadcaster') ?
                                <div>
                                    <Link to={`${process.env.PUBLIC_URL}/add-project`} className={'btn btn-outline-secondary'}><i className="fa fa-plus"></i> Add Project</Link>
                                </div> : ''}
                        </Row>

                        <MUIDataTable
                            title={"All Projects"}
                            data={this.state.rows}
                            columns={this.state.columns}
                            options={options}
                            /> 

                        <Modal className='modal-lg mr_10' isOpen={this.state.modal} backdrop={'static'}>
                            <ModalHeader>Terms and Conditions</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col sm="12">
                                        <div>
                                            {this.state.description}
                                        </div>
                                    </Col>
                                    <Col sm="12">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" checked={this.state.checked} onChange={this.handleCheckTerms} /> <b>I accept the terms and conditions.</b>
                                    </Col>
                                    <Col sm="12">
                                        <Button color="primary" type='submit' onClick={() => this.acceptTerms()}>Submit</Button>
                                        <Button className="cancel ml-2" variant="danger" onClick={() => this.modelClose()}>Cancel</Button>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                        <Modal className='modal-lg mr_10' isOpen={this.state.modal1} backdrop={'static'}>
                            <Form onSubmit={this.handleSubmit}>
                                {(currentUser.currentUser === 'superAdmin' || currentUser.currentUser === 'admin') ?
                                    <ModalHeader>Signup Invitation Email</ModalHeader> :
                                    <ModalHeader>Support Email</ModalHeader>}
                                <ModalBody>
                                    <Row>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">To</Label>
                                            <Input type="text" name="to" value={this.state.fields1["to"] || ''} id="to" placeholder="To" onChange={this.handleChangeModal.bind(this, "to")} />
                                            <div className={(this.state.errors1['to'] !== void 0 && this.state.errors1['to'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors1["to"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">CC</Label>
                                            <Input type="text" name="cc" value={this.state.fields1["cc"] || ''} id="cc" placeholder="CC" onChange={this.handleChangeModal.bind(this, "cc")} />
                                            <div className={(this.state.errors1['cc'] !== void 0 && this.state.errors1['cc'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors1["cc"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">BCC</Label>
                                            <Input type="text" name="bcc" value={this.state.fields1["bcc"] || ''} id="bcc" placeholder="BCC" onChange={this.handleChangeModal.bind(this, "bcc")} />
                                            <div className={(this.state.errors1['bcc'] !== void 0 && this.state.errors1['bcc'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors1["bcc"]}</div>
                                        </Col>
                                        <Col sm="6" className="FormGroup">
                                            <Label htmlFor="text-input">Subject</Label>
                                            <Input type="text" name="subject" value={this.state.fields1["subject"] || ''} id="subject" placeholder="Subject" onChange={this.handleChangeModal.bind(this, "subject")} />
                                            <div className={(this.state.errors1['subject'] !== void 0 && this.state.errors1['subject'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors1["subject"]}</div>
                                        </Col>
                                        <Col sm="12" className="FormGroup">
                                            <Label htmlFor="text-input">Message</Label>
                                            <Input type="textarea" name="message" value={this.state.fields1["message"] || ''} id="message" rows="10" cols="70" placeholder="Message" onChange={this.handleChangeModal.bind(this, "message")} />
                                            <div className={(this.state.errors1['message'] !== void 0 && this.state.errors1['message'].length > 0) ? "invalid-feedback d-block" : ""}>{this.state.errors1["message"]}</div>
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup className='submitbtn'>
                                                <Button color="primary" type='submit'>Submit</Button>
                                                <Button className="cancel ml-2" variant="danger" onClick={() => this.modelClose()}>Cancel</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Form>
                        </Modal>
                    </div>
                </div>
            </>
        );
    }
}

// Wrapper component will subscribe to Redux store updates
// If you don't want to subscribe to store updates, pass null or undefined in place of mapStateToProps
function mapStateToProps(state) {
    const { logedIn } = state.isLogged;
    const { currentUser } = state;
    return {
        logedIn,
        currentUser,
    };
}

// The return of connect() is a wrapper function that takes your component 
// and returns a wrapper component with the additional props it injects
export default connect(mapStateToProps)(Project);


