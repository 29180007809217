import React, { Component } from 'react';
import { toast } from 'react-toastify';
import SiteHeader from './SiteHeader';
import { Col, Row, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';
import { Container, Button } from '@material-ui/core';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode.react';
import MapPdf from './MapPdf';
import { LocalParking, FreeBreakfastOutlined, LocalHospitalOutlined, StarTwoTone, LocalCarWashOutlined } from '@material-ui/icons';
import Map from './Map';


class MapLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: { title: '', date: '', textInput: '' },
            modal: true,
            address: '',
            latLng: {
                lat: 34.120860,
                lng: -118.249230
            },
            map: {},
            marker: {}
        };
        this.handleChange = this.handleChange.bind(this);

    }

    formSubmit = (e) => {
        e.preventDefault();
        if (this.state.fields['title'].trim().length === 0) {
            toast.error('Please enter title name')
            return;
        }
        this.setState({
            modal: false
        })
    }

    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    printDocument = () => {
        const input = document.getElementById('divToPrint');
        const divHeight = input.clientHeight
        const divWidth = input.clientWidth
        const ratio = divHeight / divWidth;

        html2canvas(input, { useCORS: true, scale: '1' }).then((canvas) => {
            const imgData = canvas.toDataURL({ format: 'image/jpeg', quality: 1 });
            const pdfDOC = new jsPDF("p", "mm", "a0"); //  use a4 for smaller page
            pdfDOC.setFont("helvetica");
            pdfDOC.setFontSize(50);

            const width = pdfDOC.internal.pageSize.getWidth();
            let height = pdfDOC.internal.pageSize.getHeight();
            height = ratio * width;
            pdfDOC.addImage(imgData, 'JPEG', 0, 0, width - 20, height - 10);
            pdfDOC.textWithLink('Go to Map Location: Click here',(pdfDOC.internal.pageSize.getWidth() / 2) , 540, {align: 'left', url: `https://www.google.com/maps/?q=${this.state.latLng.lat},${this.state.latLng.lng}` });
            pdfDOC.save(`${this.state.fields['title']}.pdf`);   //Download the rendered PDF.
        });
    }
    printPdf = () => {
        var di = document.getElementById('pac-input')
        var att = document.createAttribute("hidden");
        di.setAttributeNode(att); 
        this.printDocument();
    }
    handleCallback = (childData) => {
        this.setState({
            latLng: {
                lat: childData.lat(),
                lng: childData.lng()
            }
        }, () => {
            this.state.marker.setPosition(this.state.latLng)
            this.state.map.panTo(this.state.marker.getPosition())
        })
    }

    render() {
        return (
            <>
                <Modal show={this.state.modal}>
                    <Modal.Header>
                        <Modal.Title>Add Title & RSI Stages</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => this.formSubmit(e)} >
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3 p-3">
                                        <FormControl
                                            placeholder="Title"
                                            aria-label="Title"
                                            value={this.state.fields['title']}
                                            aria-describedby="basic-addon1"
                                            name="title"
                                            onChange={this.handleChange.bind(this, 'title')}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3 p-3">
                                        <FormControl
                                            placeholder="Ex: fri & mon may 21 & 24, 20**"
                                            aria-label="date"
                                            value={this.state.fields['date']}
                                            aria-describedby="basic-addon1"
                                            name="date"
                                            onChange={this.handleChange.bind(this, 'date')}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup className="mb-3 p-3">
                                        <FormControl
                                            placeholder="Ex: crew / bg"
                                            aria-label="textInput"
                                            value={this.state.fields['textInput']}
                                            aria-describedby="basic-addon1"
                                            name="textInput"
                                            onChange={this.handleChange.bind(this, 'textInput')}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Button className="float-right mr-3 mb-3" variant="contained" color="primary" type="submit">
                                Next
                            </Button>

                        </Form>
                    </Modal.Body>
                </Modal>
                <SiteHeader />
                {this.state.modal === false && <Button className="float-right" variant="contained" color="primary" onClick={(e) => this.printPdf()}>PDF Print</Button>}
                {this.state.modal === false && <Container className="pt-5 map" id="divToPrint">
                    <Row>
                        <Col>
                            <h1 className="text-uppercase font-italic text-center">{this.state.fields['title']}</h1>
                            <div className="first-box">
                                <p className="text-center col-text-1">
                                    <b>RSI Stages</b>
                                </p>
                                <Row className="rsi-box">
                                    <Col>
                                        <p className="text-center col-text text-uppercase">
                                            <b>map is close to scale</b>
                                        </p>
                                    </Col>
                                    <Col>
                                        <p className="text-center col-text text-uppercase">
                                            <b>{this.state.fields['date'] || 'fri & mon may 21 & 24, 2021'}</b>
                                        </p>
                                    </Col>
                                    <Col className="border-right-0">
                                        <p className="text-center col-text text-uppercase">
                                            <b>{this.state.fields['textInput'] || 'crew / bg'}</b>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <div className="mt-2 qr-code" title="Aim the camera on your smart phone on the QR Code to pull up google maps link to CREW / BG PARKING">
                                    <div className="qr-border">
                                        <h5 className="text-center">QR CODE</h5>
                                        <QRCode size={90} value={`https://www.google.com/maps/?q=${this.state.latLng.lat},${this.state.latLng.lng}`} />
                                    </div>
                                </div>
                                <div className="loc-manager text-center">
                                    <b>Andrew Cone</b>
                                    <br />
                                    <b>Key Asst Loc Manager</b>
                                    <br />
                                    <b>323-868-8755</b>
                                </div>
                            </Row>
                            <Row>
                                <div className="location-steps mt-2">
                                    <small id="panel"></small>
                                </div>

                            </Row>
                        </Col>
                        <Col className="position-relative" id="map">
                            <div className="small-box position-absolute bg-white">
                                <p className="text-uppercase text-center small-box-text">
                                    <b>look for :</b>
                                </p>
                                <div className="small-box-internal mt-1">
                                    <p className="text-uppercase text-center small-box-text">
                                        <b>etp</b>
                                    </p>
                                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                    <p className="text-uppercase mb-1 text-center small-box-text rotate">
                                        <b>etp</b>
                                    </p>
                                </div>
                            </div>

                            <div style={{ height: '50vh', width: '100%', border: '2px solid #000' }}>
                                <input id="pac-input" className="location-input controls" type="text" />
                                <MapPdf parentCallback={this.handleCallback} />
                            </div>

                            <Row className="mb-5">
                                <Col className="locations-box mt-2">
                                    <Row>
                                        <Col className="locations-box-first space-125">
                                            <div className="text-center">
                                                <LocalParking className="icon-mui" />
                                                <small className="box-heading col-text text-uppercase">
                                                    <u>crew / bg parking</u>
                                                </small>
                                                <small className="box-heading-internal" id="parking"></small>
                                            </div>
                                        </Col>
                                        <Col className="locations-box-second space-125">
                                            <div className="text-center">
                                                <StarTwoTone className="icon-mui" />
                                                <LocalCarWashOutlined className="icon-mui" />
                                                <small className="box-heading col-text text-uppercase">
                                                    <u>location-basecamp-trucks</u>
                                                </small>
                                                <small className="box-heading-internal" id="location"></small>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="locations-box-third space-125">
                                            <div className="text-center">
                                                <LocalHospitalOutlined className="icon-mui" />
                                                <small className="box-heading col-text text-uppercase">
                                                    <u>nearest hospital</u>
                                                </small>
                                                <small className="box-heading-internal" id="hospital"></small>
                                            </div>
                                        </Col>
                                        <Col className="space-125">
                                            <div className="text-center">
                                                <FreeBreakfastOutlined className="icon-mui" />
                                                <small className="box-heading col-text text-uppercase">
                                                    <u>catering</u>
                                                </small>
                                                <small className="box-heading-internal" id="restaurant"></small>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="locations-box p-0 mt-2">
                                    <Map
                                        height='250px'
                                        id="singleMap"
                                        options={{
                                            center: this.state.latLng,
                                            zoom: 7,
                                            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                                            disableDefaultUI: true,
                                        }}
                                        onMapLoad={map => {
                                            map.setOptions({ draggable: false });
                                            var marker = new window.google.maps.Marker({
                                                title: 'Location',
                                                position: this.state.latLng,
                                                map: map
                                            });
                                            this.setState({
                                                map: map,
                                                marker: marker
                                            })
                                        }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>}
            </>
        );
    }
}

export default MapLocations;